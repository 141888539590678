import React from 'react'
import { Link } from 'react-router-dom'
import './categories.scss'

export default function Categories() {
    return (
        <div id="categories">
            <h1>Categories</h1>
            <div id="categoriesContainer">
                <div className='category home'>
                    <Link to={`/home`}>
                        <div>
                            <h2>Home</h2>
                        </div>
                    </Link>
                </div>
                <div className='category gun'>
                    <Link to={`/gun-and-gear-holders`}>
                        <div>
                            <h2>Gun & Gear Holders</h2>
                        </div>
                    </Link>
                </div>
                <div className='category fish'>
                    <Link to={`/fishing-rod-holders`}>
                        <div>
                            <h2>Fishing Rod Holders</h2>
                        </div>
                    </Link>
                </div>
                <div className='category thumb'>
                    <Link to={`/thumb-throttle-extenders`}>
                        <div>
                            <h2>Thumb Throttle Extenders</h2>
                        </div>
                    </Link>
                </div>
                <div className='category replace'>
                    <Link to={`/replacement-parts`}>
                        <div>
                            <h2>Replacement Parts</h2>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    )
}
