import * as types from './actionTypes';
import firebase from 'firebase/compat/app';
import { toast } from 'react-toastify';

export function logIn(formData) {
    return function (dispatch) {
        return firebase.auth().signInWithEmailAndPassword(formData.email, formData.password).then(data => {
            dispatch({ type: types.LOGGED_IN, user: data });
            toast.info("Login Successful", {
                position: "bottom-center",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            })
        }).catch(err => {
            console.log("There was an error logging in", err);
            switch (err.code) {
                case "auth/user-not-found":
                    toast.error("Invalid Email", {
                        position: "bottom-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    break;
                case "auth/wrong-password":
                    toast.error("Invalid Password", {
                        position: "bottom-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    break;
                default:
                    toast.error(err.message, {
                        position: "bottom-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
            }

        })
    }
}