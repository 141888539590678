import React, { useEffect, useState } from 'react'
import ReactModal from 'react-modal'
import { toast } from 'react-toastify';
import './modals.scss'

export default function EditDescriptionModal(props) {
    const [formData, setFormData] = useState({ title: props.description.title, text: props.description.text })
    const [index, setIndex] = useState(props.index)

    function handleChange(event) {
        const { name, value } = event.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    }

    useEffect(() => {
        if (props.index !== index) {
            setFormData({ ...props.description })
            setIndex(props.index)
        }
    }, [props.index, props.description, index])
    return (
        <ReactModal
            isOpen={props.isOpen}
            onRequestClose={() => props.handleModalClose()}
            style={{
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                width: '50%',
                height: '200px'
            }}

        >
            <div id="mainImageModal">
                <h2>{props.new ? "Create Description" : "Edit Description"} {props.index + 1}</h2>
                <div>
                    <label>Title: </label>
                    <input type="text" name="title" placeholder='title' onChange={handleChange} value={formData.title} />
                </div>
                <div>
                    <label>Text: </label>
                    <input type="text" name="text" placeholder='text' onChange={handleChange} value={formData.text} />
                </div>
                <div id="modalButtons">
                    <div id="deleteButton" onClick={() => props.editDescription(props.index, formData, true)}>Delete</div>
                    <div id="cancelButton" onClick={props.handleModalClose}>Cancel</div>
                    <div id="updateButton" onClick={() => { props.editDescription(props.index, formData) }}>Submit</div>
                </div>
            </div>
        </ReactModal>
    )
}
