import React, { useEffect, useState } from 'react'
import { connect, shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import AddDetailImageModal from '../modals/addDetailImageModal';
import DeleteDetailImageConfirmationModal from '../modals/deleteDetailImageConfirmationModal';
import DeleteProductModal from '../modals/deleteProductModal';
import EditDescriptionModal from '../modals/editDescriptionModal';
import EditMainImageModal from '../modals/editMainImageModal';
import * as actions from '../redux/actions/productActions';
import { useNavigate } from 'react-router-dom'
import './productDetail.scss'

function ProductDetail(props) {
    let categorySlug = useParams().category;
    let productId = useParams().productId
    let navigate = useNavigate()
    const location = useLocation()
    const dispatch = useDispatch()
    const [productUpdate, setProductUpdate] = useState(0)
    let starterProduct = {
        altName: '',
        amazonLink: '',
        description: [],
        imageUrl: '',
        id: uuidv4(),
        images: [],
        subCategories: {
            ATV: false,
            UTV: false,
            bike: false,
            snowmobile: false,
            gunCarts: false
        },
        modelNumber: '',
        msrb: '',
        name: '',
        upc: ''
    }
    const category = useSelector(state => state.categories.find(category => categorySlug === category.altName))
    const [mainImageModalIsOpen, setMainImageModalIsOpen] = useState(false)
    const [addDetailImageModalIsOpen, setAddDetailImageModalIsOpen] = useState(false)
    const [product, setProduct] = useState(props.propsProduct !== {} ? props.propsProduct : starterProduct)
    const [deleteDetailImageConfirmationModalIsOpen, setDetailImageConfirmationModalIsOpen] = useState(false)
    const [deleteIndex, setDeleteIndex] = useState(undefined)
    const [editDescriptionModalIsOpen, setDescriptionModalIsOpen] = useState(false)
    const [editDescriptionIndex, setEditDescriptionIndex] = useState(undefined)
    const [activeEditDescription, setActiveEditDescription] = useState({ title: '', text: '' })
    const [newDescriptoin, setNewDescription] = useState(false)
    const [deleteProductModalIsOpen, setDeleteProductModalIsOpen] = useState(false)

    useEffect(() => {
        if (productUpdate === 0 && props.propsProduct.modelNumber !== starterProduct.modelNumber) {
            setProduct(props.propsProduct)
            setProductUpdate(1)
        } else {
            dispatch(actions.loadCategory(location.state.categorySlug, { setProduct, productId, newProduct: props.propsProduct }))

            // setProduct(category.products.find(product => product.id === productId))
        }
    }, [productUpdate, starterProduct.modelNumber, location.state.productId, location.state.categorySlug, dispatch, productId, props.propsProduct])

    function handleChange(event) {
        const { name, value } = event.target;
        setProduct(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    }

    function updateSubCategories(name) {
        if (!product.subCategories) {
            setProduct(prevProduct => ({
                ...prevProduct,
                subCategories: {
                    ATV: false,
                    UTV: false,
                    bike: false,
                    snowmobile: false,
                    gunCarts: false
                }
            }))
        }
        setProduct(prevProduct => ({
            ...prevProduct,
            subCategories: {
                ...prevProduct.subCategories,
                [name]: !prevProduct.subCategories[name]
            }
        }))
    }

    function editMainImage(file, setShowLoader) {
        // If the file has any spaces or parenthesis in its name it will not display
        // So we get rid of them :)
        Object.defineProperty(file, "name", {
            writable: true,
            value: file.name.replace(/ /g, "")
        });
        file.name = file.name.replace(/\(/g, "");
        file.name = file.name.replace(/\)/g, "");
        // console.log(category)
        if (productId === "new") {
            dispatch(actions.editMainImage(file, category, product, handleModalClose, true))
        } else {
            dispatch(actions.editMainImage(file, category, product, handleModalClose, false, setShowLoader))
        }
    }

    function addDetailImage(file, showSpinner) {
        Object.defineProperty(file, "name", {
            writable: true,
            value: file.name.replace(/ /g, "")
        });
        file.name = file.name.replace(/\(/g, "");
        file.name = file.name.replace(/\)/g, "");
        dispatch(actions.addDetailImage(file, categorySlug, productId, handleAddModalClose, setProduct, showSpinner))
    }

    function deleteDetailImage(index) {
        dispatch(actions.deleteDetailImage(index, categorySlug, productId, 0, setProduct, setProductUpdate))
        handleDeleteDetailModalClose()
    }

    function editDescription(index, description, deleteDescription) {
        dispatch(actions.editDescriptions(index, description, categorySlug, product.id, setProduct, deleteDescription))
        handleEditDescriptionModalClose()
        if (newDescriptoin) {
            setNewDescription(false)
        }
    }

    function deleteProduct() {
        navigate("/")
        dispatch(actions.deleteProduct(product.id, category.id, product, navigate, category.docid))
    }

    function saveProduct(event) {
        event.preventDefault()
        dispatch(actions.saveProduct(product, category))
    }

    function openMainImageModal() {
        setMainImageModalIsOpen(true)
    }

    function handleModalClose(setModal) {
        setMainImageModalIsOpen(false)
    }

    function openAddDetailImageModal() {
        setAddDetailImageModalIsOpen(true)
    }

    function handleAddModalClose() {
        setAddDetailImageModalIsOpen(false)
    }

    function handleDeleteDetailModalClose() {
        setDetailImageConfirmationModalIsOpen(false)
    }

    function openDeleteDetailImgModal() {
        setDetailImageConfirmationModalIsOpen(true)
    }

    function handleEditDescriptionModalClose() {
        setDescriptionModalIsOpen(false)
    }

    function openDescriptionModal() {
        setDescriptionModalIsOpen(true)
    }

    function handleDeleteProductModalClose() {
        setDeleteProductModalIsOpen(false)
    }

    function openDeleteProductModal() {
        setDeleteProductModalIsOpen(true)
    }

    return (
        <>
            <EditMainImageModal
                isOpen={mainImageModalIsOpen}
                handleModalClose={() => handleModalClose(setMainImageModalIsOpen)}
                editMainImage={editMainImage}
            />
            <AddDetailImageModal
                isOpen={addDetailImageModalIsOpen}
                handleModalClose={handleAddModalClose}
                addDetailImage={addDetailImage}
            />
            <DeleteDetailImageConfirmationModal
                isOpen={deleteDetailImageConfirmationModalIsOpen}
                handleModalClose={handleDeleteDetailModalClose}
                deleteImage={deleteDetailImage}
                index={deleteIndex}
            />
            <EditDescriptionModal
                isOpen={editDescriptionModalIsOpen}
                handleModalClose={handleEditDescriptionModalClose}
                description={activeEditDescription}
                index={editDescriptionIndex}
                editDescription={editDescription}
                new={newDescriptoin}
            />
            <DeleteProductModal
                isOpen={deleteProductModalIsOpen}
                handleModalClose={handleDeleteProductModalClose}
                deleteProduct={deleteProduct}
            />
            {product && product.id ?

                <div id="productDetailContainer">
                    <h1>{productId === 'new' ? 'Create New Product' : `Edit Product - ${product.name}`}</h1>
                    <form>
                        <div id="topRow">
                            <div id="imagesContainer">
                                <div id="mainImage" onClick={openMainImageModal} style={product && product.downloadableImageUrl ? { backgroundImage: `url(${product.downloadableImageUrl})` } : {}}>
                                    <div>{productId === 'new' ? 'Add' : `Edit`}</div>
                                </div>
                                <div id="detailImagesContainer">
                                    {product && product.images.map((image, index) => (
                                        <div
                                            className='detailImage'
                                            key={uuidv4()}
                                            style={image.downloadableImageUrl ? { backgroundImage: `url(${image.downloadableImageUrl})` } : {}}
                                            onClick={() => { setDeleteIndex(index); openDeleteDetailImgModal() }}
                                        >Delete</div>
                                    ))}
                                    <div className='detailImage addSubImageContainer' onClick={openAddDetailImageModal}>
                                        <div className='addSubImage'>+</div>
                                    </div>
                                </div>
                            </div>
                            <div id="rightSide">
                                <div id="smallInputs">
                                    <div className='inputContainer'>
                                        <div>Name: </div>
                                        <div>
                                            <input type="text" name="name" placeholder='Product Name' onChange={handleChange} value={product.name} />
                                            <div className='inputBorder'></div>
                                        </div>
                                    </div>
                                    <div className='inputContainer'>
                                        <div>Alt Name: </div>
                                        <div>
                                            <input type="text" name="altName" placeholder='Alt Name' onChange={handleChange} value={product.altName} />
                                            <div className='inputBorder'></div>
                                        </div>
                                    </div>
                                    <div className='inputContainer'>
                                        <div>Model #: </div>
                                        <div>
                                            <input type="text" name="modelNumber" placeholder='Model Number' onChange={handleChange} value={product.modelNumber} />
                                            <div className='inputBorder'></div>
                                        </div>
                                    </div>
                                    <div className='inputContainer'>
                                        <div>UPC: </div>
                                        <div>
                                            <input type="text" name="upc" placeholder='Model Number' onChange={handleChange} value={product.upc} />
                                            <div className='inputBorder'></div>
                                        </div>
                                    </div>
                                    <div className='inputContainer'>
                                        <div>MSRB: </div>
                                        <div>
                                            <input type="text" name="msrb" placeholder='Model Number' onChange={handleChange} value={product.msrb} />
                                            <div className='inputBorder'></div>
                                        </div>
                                    </div>
                                </div>
                                <div id="amazonLinkContainer">
                                    <div className='inputContainer'>
                                        <div>Link: </div>
                                        <div id="amazonLinkRow">
                                            <input type="text" name="amazonLink" placeholder='Model Number' onChange={handleChange} value={product.amazonLink} />
                                            <div className='inputBorder'></div>
                                        </div>
                                    </div>
                                </div>
                                <div id="descriptionsContainer">
                                    {product.description.map((description, index) => (
                                        <div className='descriptionContainer' key={uuidv4()}>
                                            <div>
                                                <h2>Description {index + 1}</h2>
                                                <p>{description.title} {description.text}</p>
                                            </div>

                                            <div className='editDescriptionButton' onClick={() => {
                                                setActiveEditDescription(description);
                                                setEditDescriptionIndex(index);
                                                openDescriptionModal();
                                            }}>Edit</div>
                                        </div>
                                    ))}
                                    <div className='descriptionContainer addDescription' onClick={() => {
                                        setActiveEditDescription({ title: '', text: '' })
                                        setEditDescriptionIndex(product.description.length)
                                        setNewDescription(true)
                                        openDescriptionModal()
                                    }}>
                                        <div>+</div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div id="categories">
                            {/* <h2>Main Category: <span id="categorySlug">{categorySlug}</span></h2>xs */}
                            <h2 id="subCategoryHeader">Sub Categories</h2>
                            <div id='options'>
                                <div className='optionContainer'>
                                    <div
                                        className="optionCircle"
                                        onClick={() => updateSubCategories('ATV')}
                                        style={{ backgroundColor: product.subCategories['ATV'] ? 'rgba(255,255,255,0.4)' : 'transparent' }}
                                    ></div>
                                    <div className='optionLabel'>ATV's</div>
                                </div>
                                <div className='optionContainer'>
                                    <div
                                        className="optionCircle"
                                        onClick={() => updateSubCategories('UTV')}
                                        style={{ backgroundColor: product.subCategories['UTV'] ? 'rgba(255,255,255,0.4)' : 'transparent' }}
                                    ></div>
                                    <div className='optionLabel'>UTV's</div>
                                </div>
                                <div className='optionContainer'>
                                    <div
                                        className="optionCircle"
                                        onClick={() => updateSubCategories('bike')}
                                        style={{ backgroundColor: product.subCategories['bike'] ? 'rgba(255,255,255,0.4)' : 'transparent' }}
                                    ></div>
                                    <div className='optionLabel'>Bikes</div>
                                </div>
                                <div className='optionContainer'>
                                    <div
                                        className="optionCircle"
                                        onClick={() => updateSubCategories('snowmobile')}
                                        style={{ backgroundColor: product.subCategories['snowmobile'] ? 'rgba(255,255,255,0.4)' : 'transparent' }}
                                    ></div>
                                    <div className='optionLabel'>Snowmobiles</div>
                                </div>
                                <div className='optionContainer'>
                                    <div
                                        className="optionCircle"
                                        onClick={() => updateSubCategories('gunCarts')}
                                        style={{ backgroundColor: product.subCategories['gunCarts'] ? 'rgba(255,255,255,0.4)' : 'transparent' }}
                                    ></div>
                                    <div className='optionLabel'>Gun Carts</div>
                                </div>
                            </div>
                            <div id="importantButtons">
                                <div id="deleteButton" onClick={() => openDeleteProductModal()}>Delete</div>
                                <input onClick={saveProduct} type="submit" value="Save" />
                            </div>
                        </div>
                    </form>
                </div>
                :
                <div>Loading</div>
            }
        </>
    )
}

const mapDispatchToProps = {
}

function mapStateToProps(state, ownProps) {
    if (!state.categories || state.categories.length === 0) {
        return {
            propsProduct: {
                altName: '',
                amazonLink: '',
                description: [],
                imageUrl: '',
                images: [],
                id: uuidv4(),
                subCategories: {
                    ATV: false,
                    UTV: false,
                    bike: false,
                    snowmobile: false,
                    gunCarts: false
                },
                modelNumber: '',
                msrb: '',
                name: '',
                upc: ''
            }
        }
    } else if (ownProps.location.state.productId === "new") {
        return {
            propsProduct: {
                altName: '',
                amazonLink: '',
                description: [],
                imageUrl: '',
                id: uuidv4(),
                images: [],
                subCategories: {
                    ATV: false,
                    UTV: false,
                    bike: false,
                    snowmobile: false,
                    gunCarts: false
                },
                modelNumber: '',
                msrb: '',
                name: '',
                upc: ''
            }
        }
    }
    return { propsProduct: state.categories.find(category => category.altName === ownProps.location.state.categorySlug).products.find(product => product.id === ownProps.location.state.productId) }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetail)