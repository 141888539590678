import * as types from './actionTypes';
import firebase from 'firebase/compat/app';
import { deleteObject, getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage'
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom'

function receivedAllImageUrls(products) {
    let outcome = true;

    products.forEach(product => {
        if (!product.downloadableImageUrl) {
            outcome = false
        }
        product.images.forEach(img => {
            if (!img.downloadableImageUrl) {
                outcome = false
            }
        })
    })

    return outcome
}

export function loadCategory(altName, optData) {
    return function (dispatch) {
        const db = firebase.firestore()
        db.collection('categories').where('altName', '==', altName).get().then(snapshot => {
            let data;
            snapshot.forEach(item => {
                if (item.data().altName === altName) {
                    data = {
                        docid: item.id,
                        products: [],
                        ...item.data()
                    }
                }
            })

            db.collection(`categories/${data.docid}/products`).get().then(snapshot => {
                const storage = getStorage()
                snapshot.forEach(product => {
                    data.products.push({ docid: product.id, ...product.data() })
                })
                data.products.forEach((product, index) => {
                    getDownloadURL(ref(storage, product.imageUrl)).then(url => {
                        data.products[index]['downloadableImageUrl'] = url
                        if (receivedAllImageUrls(data.products)) {
                            dispatch({ type: types.LOAD_CATEGORY, data })
                            if (optData && optData.productId === "new") {
                                optData.setProduct(optData.newProduct)
                            } else if (optData) {
                                optData.setProduct(data.products.find(product => optData.productId === product.id))
                            }
                        }
                    })
                    product.images.forEach((img, imgIndex) => {
                        getDownloadURL(ref(storage, img.imageUrl)).then(url => {
                            data.products[index].images[imgIndex]['downloadableImageUrl'] = url
                            if (receivedAllImageUrls(data.products)) {
                                dispatch({ type: types.LOAD_CATEGORY, data })
                                if (optData && optData.productId === "new") {
                                    optData.setProduct(optData.newProduct)
                                } else if (optData) {
                                    optData.setProduct(data.products.find(product => optData.productId === product.id))
                                }
                            }
                        })
                    })
                })
            })
                .catch(err => {
                    console.log(err)
                    toast.error(err)
                })
        })
            .catch(err => {
                console.log(err)
                toast.error(err)
            })
    }
}

export function editMainImage(image, category, product, handleModalClose, newProduct, setShowLoader = () => { }) {
    return function (dispatch, getState) {
        const storage = getStorage();
        const db = firebase.firestore()
        const imageName = uuidv4() + image.name
        const imageRef = ref(storage, imageName)
        let state = getState()

        uploadBytes(imageRef, image).then(snapshot => {
            if (product.imageUrl !== '' && product.imageUrl !== 'pexels-fernando-arcos-211122.jpg') {
                deleteObject(ref(storage, product.imageUrl)).catch(err => console.log(err))
            }
            getDownloadURL(imageRef).then(url => {
                db.collection(`categories/${category.docid}/products`).doc(product.docid).set({ imageUrl: imageName, id: product.id, images: product.images }, { merge: true }).then(snapshot => {
                    dispatch({
                        type: types.UPDATE_MAIN_IMAGE,
                        imageUrl: imageName,
                        downloadableImageUrl: url,
                        productId: product.id,
                        categoryId: category.id,
                        newProduct,
                        product
                    })
                    handleModalClose()
                    setShowLoader(false)
                })
                    .catch(err => {
                        console.log(err)
                        toast.error(err)
                    })

            })
                .catch(err => {
                    console.log(err)
                    toast.error(err)
                })
        })
            .catch(err => {
                console.log(err)
                toast.error(err)
            })
    }
}

export function addDetailImage(image, categorySlug, productId, handleModalClose, setProduct, showSpinner) {
    return function (dispatch, getState) {
        const storage = getStorage();
        const db = firebase.firestore()
        const imageName = uuidv4() + image.name
        const imageRef = ref(storage, imageName)
        let state = getState()
        let category = state.categories.find(elem => elem.altName === categorySlug)
        let product = category.products.find(prod => prod.id === productId)

        uploadBytes(imageRef, image).then(snapshot => {
            getDownloadURL(imageRef).then(url => {
                product.images.push({ imageUrl: imageName })
                db.collection(`categories/${category.docid}/products`).doc(product.docid).set({ images: product.images }, { merge: true }).then(snapshot => {
                    product.images.forEach((img, index) => {
                        if (img.imageUrl === imageName) {
                            product.images[index]['downloadableImageUrl'] = url
                        }
                    })
                    dispatch({
                        type: types.ADD_DETAIL_IMAGE,
                        categoryId: category.id,
                        productId: productId,
                        product
                    })
                    setProduct(product)
                    handleModalClose()
                    showSpinner(false)
                }).catch(err => {
                    console.log(err)
                    toast.error(err)
                })
            }).catch(err => {
                console.log(err)
                toast.error(err)
            })
        }).catch(err => {
            console.log(err)
            toast.error(err)
        })
    }
}

export function deleteDetailImage(index, categorySlug, productId, num, setProduct, setProductUpdate) {
    return function (dispatch, getState) {
        const storage = getStorage();
        const db = firebase.firestore()
        let state = getState()
        let category = state.categories.find(elem => elem.altName === categorySlug)
        let product = category.products.find(prod => prod.id === productId)

        deleteObject(ref(storage, product.images[index].imageUrl)).then(stuff => {
            product.images.splice(index, 1)
            let dbImages = JSON.parse(JSON.stringify(product.images))
            product.images.forEach((img, index) => {
                delete dbImages[index].downloadableImageUrl
            })
            console.log(product.images)
            db.collection(`categories/${category.docid}/products`).doc(product.docid).set({ images: dbImages }, { merge: true }).then(snapshot => {
                dispatch({
                    type: types.DELETE_DETAIL_IMAGE,
                    categoryId: category.id,
                    productId: product.id,
                    images: product.images
                })
                setProduct(product)
                setProductUpdate(prev => prev + 1)
            }).catch(err => {
                console.log(err)
                toast.error(err)
            })
        }).catch(err => {
            console.log(err)
            toast.error(err)
        })
    }
}

export function editDescriptions(index, newDescription, categorySlug, productId, setProduct, deleteDescription) {
    return function (dispatch, getState) {
        const db = firebase.firestore()
        let state = getState()
        let category = state.categories.find(elem => elem.altName === categorySlug)
        let product = category.products.find(prod => prod.id === productId)

        if (deleteDescription) {
            product.description.splice(index, 1)
        } else {
            product.description[index] = newDescription
        }

        db.collection(`categories/${category.docid}/products`).doc(product.docid).set({ description: product.description }, { merge: true }).then(() => {
            dispatch({
                type: types.EDIT_DESCRIPTIONS,
                product: product,
                productId,
                categoryId: category.id
            })
            setProduct(product)
        }).catch(err => {
            console.log(err)
            toast.error(err)
        })
    }
}

export function createProduct(categorySlug, productName, navigate) {
    return function (dispatch, getState) {
        let state = getState()
        const db = firebase.firestore()
        let category = state.categories.find(elem => elem.altName === categorySlug)
        let newProduct = {
            altName: '',
            amazonLink: '',
            description: [],
            imageUrl: 'pexels-fernando-arcos-211122.jpg',
            id: uuidv4(),
            images: [],
            subCategories: {
                ATV: false,
                UTV: false,
                bike: false,
                snowmobile: false,
                gunCarts: false
            },
            modelNumber: '',
            msrb: '',
            name: productName,
            upc: ''
        }

        db.collection(`categories/${category.docid}/products`).add(newProduct).then((docRef) => {
            newProduct['docid'] = docRef.id
            dispatch({
                type: types.CREATE_PRODUCT,
                newProduct,
                categoryId: category.id
            })
            navigate(`${newProduct.id}`, {
                state: {
                    productId: newProduct.id,
                    categorySlug
                }
            })


        }).catch(err => {
            toast.error(err)
            console.log(err)
        })
    }
}

export function deleteProduct(productId, categoryId, product, navigate, categorydocid) {
    return function (dispatch) {
        const db = firebase.firestore()
        const storage = getStorage()
        if (product.imageUrl !== 'pexels-fernando-arcos-211122.jpg') {
            deleteObject(ref(storage, product.imageUrl)).catch(err => { console.log(err); toast.error(err) })
        }
        product.images.forEach(img => {
            deleteObject(ref(storage, img.imageUrl)).catch(err => { console.log(err); toast.error(err) })
        })
        db.collection(`categories/${categorydocid}/products`).doc(product.docid).delete().then(() => {
            dispatch({
                type: types.DELETE_PRODUCT,
                productId,
                categoryId
            })
            toast.info("Product deleted!")
        }).catch(err => {
            toast.error(err)
            console.log(err)
        })
    }
}

export function saveProduct(product, category) {
    return function (dispatch) {
        const db = firebase.firestore()

        db.collection(`categories/${category.docid}/products`).doc(product.docid).update({
            altName: product.altName,
            amazonLink: product.amazonLink,
            modelNumber: product.modelNumber,
            msrb: product.msrb,
            name: product.name,
            upc: product.upc,
            subCategories: product.subCategories
        }).then(() => {
            dispatch({
                type: types.SAVE_PRODUCT,
                product,
                category
            })
            toast.info('Product Saved!')
        }).catch(err => {
            console.log(err)
            toast.error(err)
        })
    }
}