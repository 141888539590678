import { Route, Routes } from 'react-router-dom';
import './App.css';
import Login from './login/login';
import React, { useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from 'react-toastify';
import Dashboard from './dashboard/dashboard';

function App() {

  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setLoggedIn(true);
      } else {
        //Not logged in
      }
    });
  });

  function handleSignOut() {
    firebase.auth().signOut().then(() => {
      setLoggedIn(false);
    })
    toast.info("Logged Out", {
      position: "bottom-center",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
    })
  }

  return (
    <div className="App">
      <Routes>
        {loggedIn ? <Route path="*" element={<Dashboard handleSignOut={handleSignOut} />} /> : <Route path="*" element={<Login />} />}
      </Routes>
      <ToastContainer
        position="bottom-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
      />
    </div>
  );
}

export default App;
