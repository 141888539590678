import { actionTypes } from 'redux-firestore';
import * as types from '../actions/actionTypes'
import initialState from "./initialState";

export default function productReducer(state = initialState.categories, action) {
    switch (action.type) {
        case types.LOAD_CATEGORY:
            let noDuplicates = true
            state.forEach(category => {
                if (category.id === action.data.id) {
                    noDuplicates = false
                }
            })
            if (noDuplicates) {
                let newState = [...state]
                newState.push(action.data)
                return newState
            } else {
                return state
            }
        case types.UPDATE_MAIN_IMAGE:
            // action: {imageUrl, downloadableImageUrl, categoryId, productId}
            let newState = [...state]
            newState.forEach((category, index) => {
                if (category.id === action.categoryId) {
                    category.products.forEach((product, index2) => {
                        if (product.id === action.productId) {
                            newState[index].products[index2] = {
                                ...product,
                                imageUrl: action.imageUrl,
                                downloadableImageUrl: action.downloadableImageUrl
                            }
                        }
                    })
                }
            })
            return newState
        case types.ADD_DETAIL_IMAGE:
            let updatedState = [...state]
            updatedState.forEach((category, index) => {
                if (category.id === action.categoryId) {
                    category.products.forEach((product, index2) => {
                        if (product.id === action.productId) {
                            updatedState[index].products[index2] = action.product
                        }
                    })
                }
            })
            return updatedState

        case types.DELETE_DETAIL_IMAGE:
            let myState = [...state]
            myState.forEach((category, index) => {
                if (category.id === action.categoryId) {
                    category.products.forEach((product, index2) => {
                        if (product.id === action.productId) {
                            myState[index].products[index2].images = action.images
                        }
                    })
                }
            })
            return myState
        case types.EDIT_DESCRIPTIONS:
            let detailState = [...state]
            detailState.forEach((category, index) => {
                if (category.id === action.categoryId) {
                    category.products.forEach((product, index2) => {
                        if (product.id === action.productId) {
                            detailState[index].products[index2].description = action.product.description
                        }
                    })
                }
            })
            return detailState
        case types.CREATE_PRODUCT:
            let someState = [...state]
            someState.forEach((category, index) => {
                if (category.id === action.categoryId) {
                    someState[index].products.push(action.newProduct)
                }
            })
            return someState
        case types.DELETE_PRODUCT:
            let deleteState = [...state]
            deleteState.forEach((category, index) => {
                if (category.id === action.categoryId) {
                    category.products.forEach((product, index2) => {
                        if (product.id === action.productId) {
                            deleteState[index].products.splice(index2, 1)
                        }
                    })
                }
            })
            return deleteState
        case types.SAVE_PRODUCT:
            let saveState = [...state]
            saveState.forEach((category, index) => {
                if (category.id === action.category.id) {
                    category.products.forEach((product, index2) => {
                        if (product.id === action.product.id) {
                            saveState[index].products[index2] = action.product
                        }
                    })
                }
            })
            return saveState
        default:
            return state
    }
}