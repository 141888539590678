import React, { useState } from 'react'
import ReactModal from 'react-modal'
import { toast } from 'react-toastify';
import Spinner from '../spinner/spinnerSmall';
import './modals.scss'

ReactModal.setAppElement("#root")

export default function AddDetailImageModal(props) {
    const [fileName, setFileName] = useState('*Upload Picture');
    const [currentFile, setCurrentFile] = useState('');
    const [fileChanged, setFileChanged] = useState(0);
    const [showSpinner, setShowSpinner] = useState(false)

    function onFileChange(event) {
        setFileName(event.target.files[0].name);
        setCurrentFile(event.target.files[0]);
        setFileChanged(fileChanged + 1)
    }

    function onSubmit() {
        if (!props.loading) {
            if (currentFile !== '' && fileChanged !== 0) {
                setShowSpinner(true)
                props.addDetailImage(currentFile, setShowSpinner);
            } else {
                toast.error('Must add an image to update. Click "cancel" to exit.');
            }
        }
    }

    return (
        <ReactModal
            isOpen={props.isOpen}
            onRequestClose={() => props.handleModalClose()}
            style={{
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                width: '50%',
                height: '200px'
            }}

        >
            <div id="mainImageModal">
                <h2>Add a New Detail Image</h2>
                <label id="fileUploadButton">
                    <input type="file" accept="image/*" onChange={onFileChange} />
                    {fileName}
                </label>
                <div id="modalButtons">
                    {showSpinner ? <Spinner /> : <></>}
                    <div id="cancelButton" onClick={props.handleModalClose}>Cancel</div>
                    <div id="updateButton" onClick={onSubmit}>Add</div>
                </div>
            </div>
        </ReactModal>
    )
}
