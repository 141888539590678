import React from 'react'
import { Link, Route, Routes, useLocation, useMatch } from 'react-router-dom'
import Categories from '../categories/categories'
import Home from '../home/home'
import ProductDetail from '../productDetail/productDetail'
import Products from '../products/products'
import './dashboard.scss'

export default function Dashboard(props) {
    let location = useLocation()
    return (
        <div id="dashboardContainer">
            <div id="nav">
                <h1><Link to="/" >All Rite Products Admin Dashboard</Link></h1>
                <div id="signOutBtn" onClick={() => { props.handleSignOut() }}>Sign Out</div>
            </div>
            <div id="subViews">
                <Routes>
                    <Route exact path="" element={<Categories />} />
                    <Route exact path={`/home`} element={<Home />} />
                    <Route exact path={`/:category`} element={<Products />} />
                    <Route exact path={'/:category/:productId'} element={<ProductDetail location={location} />} />
                </Routes>
            </div>
        </div>
    )
}
