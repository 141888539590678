import React from 'react'
import ReactModal from 'react-modal'
import { toast } from 'react-toastify';
import './modals.scss'

export default function DeleteProductModal(props) {
    return (
        <ReactModal
            isOpen={props.isOpen}
            onRequestClose={() => props.handleModalClose()}
            style={{
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                width: '50%',
                height: '200px'
            }}

        >
            <div id="mainImageModal">
                <h2>Are you sure?</h2>
                <p>You are about to delete this product. There is no way to undo this action.</p>
                <div id="modalButtons">
                    <div id="cancelButton" onClick={() => props.handleModalClose()}>Cancel</div>
                    <div id="deleteButton" onClick={() => props.deleteProduct()}>Continue</div>
                </div>
            </div>
        </ReactModal>
    )
}
