import { combineReducers } from "@reduxjs/toolkit";
import authReducer from "./authReducer";
import productReducer from "./productReducer";

const rootReducer = combineReducers({
    auth: authReducer,
    categories: productReducer

});

export default rootReducer