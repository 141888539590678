import React, { useState } from 'react'
import * as authActions from '../redux/actions/authActions';
import { connect } from 'react-redux';
import './login.scss'

function Login(props) {
    const [formData, setFormData] = useState({ email: '', password: '' });

    function handleChange(event) {
        const { name, value } = event.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    }

    function onSubmit(event) {
        event.preventDefault();
        props.login(formData);
    }

    return (
        <div className='loginContainer'>
            <div className='leftImg'></div>
            <div className='rightSide'>
                <div className='form'>
                    <h1>All Rite Products Login</h1>
                    <form onSubmit={onSubmit}>
                        <div className='row'>
                            <input name="email" type="email" placeholder="Email:" onChange={handleChange} value={formData.email} />
                            <div className='inputBorder'></div>
                        </div>
                        <div>
                            <input name="password" type="password" placeholder="Password:" onChange={handleChange} value={formData.password} />
                            <div className='inputBorder'></div>
                        </div>
                        <input type="submit" />
                    </form>
                </div>
            </div>

        </div>
    )
}

function mapStateToProps(state, ownProps) {
    return { data: [] }
}

export default connect(mapStateToProps, { login: authActions.logIn })(Login);