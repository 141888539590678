import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./reducers";
import thunk from "redux-thunk";

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/analytics';
import 'firebase/compat/storage';

const firebaseConfig = {
    apiKey: "AIzaSyDV7G5O1p96DHzQFeYs_7V0jN1pA0xnZwc",
    authDomain: "all-rite-products.firebaseapp.com",
    databaseURL: "https://all-rite-products-default-rtdb.firebaseio.com",
    projectId: "all-rite-products",
    storageBucket: "all-rite-products.appspot.com",
    messagingSenderId: "86235375931",
    appId: "1:86235375931:web:a1a2b4f6adb6e7f344ef1e",
    measurementId: "G-G5WC346635"
};

firebase.initializeApp(firebaseConfig);
firebase.firestore();
firebase.analytics();
firebase.storage();




export default function configureAppStore(preloadedState) {
    return configureStore({
        reducer: rootReducer,
        middleware: [thunk],
        preloadedState
    })
}