import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom'
import * as actions from '../redux/actions/productActions'
import './products.scss'
import firebase from 'firebase/compat/app';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom'
import { act } from 'react-dom/test-utils';
import Spinner from '../spinner/spinner';

export default function Products() {
    let categorySlug = useParams().category;
    const category = useSelector(state => state.categories.find(category => categorySlug === category.altName))
    const dispatch = useDispatch()
    const [showForm, setShowForm] = useState(false)
    const [formData, setFormData] = useState({ name: '' });
    let navigate = useNavigate()

    function handleChange(event) {
        const { name, value } = event.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    }

    function handleSubmit() {
        if (formData.name !== '') {
            dispatch(actions.createProduct(categorySlug, formData.name, navigate))
        }
    }

    function validSlug(slug) {
        switch (slug) {
            case "gun-and-gear-holders":
            case "fishing-rod-holders":
            case "thumb-throttle-extenders":
            case "replacement-parts":
                return true
            default:
                return false
        }
    }

    useEffect(() => {
        // if (category == undefined && validSlug(categorySlug)) {
        //Go pull in the category data from db
        dispatch(actions.loadCategory(categorySlug))
        // }
    }, [category, categorySlug, dispatch])

    return (
        <>
            {category ?
                <div id="productsContainer">
                    <h1>{category.name}</h1>
                    <div id="products">
                        {category.products.map(product => (
                            <Link to={`${product.id}`} state={{ productId: product.id, categorySlug }} key={product.id}>
                                <div className='product'>
                                    <div className='productImage' style={{ backgroundImage: `url(${product.downloadableImageUrl})` }}></div>
                                    <div className='productName'>{product.name}</div>
                                </div>
                            </Link>
                        ))}
                    </div>
                    <div id="plusButton" style={showForm ? {
                        width: "300px",
                        height: "100px",
                        padding: "15px"
                    } : {
                        height: "90px",
                        width: "90px",
                        borderRadius: "100%",
                        display: "flex",
                        fontSize: "50px"
                    }}>
                        {showForm ?
                            <div>
                                <h3>New Product</h3>
                                <input type="text" name="name" placeholder="New Product Name" onChange={handleChange} value={formData.name} />
                                <div className="inputBorder"></div>
                                <div onClick={handleSubmit} id="createBtn">Create</div>
                            </div>
                            :

                            <div className='plus' onClick={() => setShowForm(true)}>+</div>
                        }
                    </div>
                </div>

                :

                <Spinner />
            }
        </>
    )
}
